import React from "react"
import { BaseService } from "../services/base-service"
import "./content.css"
import "./emailcontactus-content.css"

export class GetBeInspirational extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOnVideoPlayMode: false,
    }
  }

  componentDidMount() {
    const script = document.createElement("script")
    script.src = "https://webforms.pipedrive.com/f/loader"
    script.async = true
    document.body.appendChild(script)
  }

  renderTopTextElement = () => (
    <div className="email-contact-us-content-top-text-container-div">
      <div className="email-contact-us-content-top-title-div">
        Build A Meaningful Brand Relationship
      </div>
      <div className="email-contact-us-content-top-description-div">
        Tiggbee enables students across North America to learn about your
        company, what your company offers and what your company represents.
      </div>
    </div>
  )

  handleOnClickPlayVideo = () => {
    const WatchTiggbeeInAction = document.getElementById("WatchTiggbeeInAction")
    if (WatchTiggbeeInAction) {
      this.setState(
        (state) => {
          const newState = {
            isOnVideoPlayMode: true,
          }
          return newState
        },
        () => {
          if (WatchTiggbeeInAction.play) WatchTiggbeeInAction.play()
        }
      )
    }
  }

  renderVideoPart = () => (
    <div className="video-player-wrapper-div">
      <div className="video-player-container-div">
        <video controls id="WatchTiggbeeInAction" preload="metadata">
          <source
            src={BaseService.getVideoURL(
              "/public/public-files/Tiggbee+In+Action.mp4"
            )}
            type="video/mp4"
          />
        </video>
      </div>
      {!this.state.isOnVideoPlayMode ? (
        <>
          <div
            onClick={this.handleOnClickPlayVideo}
            className="video-player-poster-div"
            style={{
              backgroundImage: "URL(/email-contact-us-video-background.png)",
            }}
          >
            <div className="video-player-poster-title-div">
              Watch Tiggbee In Action
            </div>
          </div>
          <img
            onClick={this.handleOnClickPlayVideo}
            className="video-player-play-img"
            src="/email-contact-us-video-play.svg"
            alt=""
          />
        </>
      ) : (
        <></>
      )}
    </div>
  )

  renderVideoPresentElement = () => (
    <div className="email-contact-us-content-video-present-container-div">
      <div className="email-contact-us-content-video-video-div">
        {this.renderVideoPart()}
      </div>
      <div className="email-contact-us-content-video-present-div">
        <div className="email-contact-us-content-video-present-title-div">
          Tiggbee is an interactive video-driven platform created to ignite
          students' passions about your company and their future careers.
        </div>
        <div className="email-contact-us-content-video-present-description-div">
          Tiggbee's platform revolutionizes career exploration by introducing
          students, grades 4-12, to diverse speakers in various industries
          through meticulously crafted, five-minute video events every week.
          This means{" "}
          <b>
            your business gets five plus minutes of undivided student attention
          </b>
          , where they learn about your industry, business, and the career path
          to get there in a conversational and engaging way.
        </div>
      </div>
    </div>
  )

  renderBecomePartnerReasonElement = () => (
    <div className="email-contact-us-content-become-partner-reason-container-div">
      <div className="email-contact-us-content-become-partner-reason-title-div">
        WHY COMPANIES PARTNER WITH TIGGBEE?
      </div>
      <div className="email-contact-us-content-become-partner-reasons-div">
        <div className="email-contact-us-content-become-partner-reason-image-div">
          <div />
          <img
            src="/email-contact-us-content-become-partner-reason-image.png"
            alt=""
          />
        </div>
        <div className="email-contact-us-content-become-partner-reason-list-lable-div">
          WITH YOUR VIDEO EVENTS...
        </div>
        <div className="email-contact-us-content-become-partner-reason-list-items-div">
          <div>
            <div>
              <span>•</span>
            </div>
            Spread brand awareness, products and services in a budget friendly
            way
          </div>
          <div>
            <div>
              <span>•</span>
            </div>
            Share your career opportunities with your future workforce
          </div>
          <div>
            <div>
              <span>•</span>
            </div>
            Give students first-hand knowledge on how their skills and talents
            may be used at your company
          </div>
          <div>
            <div>
              <span>•</span>
            </div>
            Build relationships with students in their day to day lives
          </div>
          <div>
            <div>
              <span>•</span>
            </div>
            Showcase employees embodying your brand's values
          </div>
          <div>
            <div>
              <span>•</span>
            </div>
            Give back to your community as the industry leader
          </div>
        </div>
      </div>
    </div>
  )

  renderHowItWorksElement = () => (
    <div className="email-contact-us-content-how-it-works-container-div">
      <img src="/email-contact-us-content-how-it-works-image.png" alt="" />
      <div>
        <div className="email-contact-us-content-how-it-works-title-div">
          HOW IT WORKS.
        </div>
        <div className="email-contact-us-content-how-it-works-steps-div">
          <div>
            <b>Step 1:</b>
            Collaborate and create inspiring content with our creative team.
          </div>
          <div>
            <b>Step 2:</b>
            Schedule filming: remote or onsite depending on your company's
            preference.
          </div>
          <div>
            <b>Step 3:</b>
            We schedule the premiere of your featured 5-minute video event!
          </div>
          <div>
            <b>Step 4:</b>
            Respond to students' top-voted questions. We make it easy to record
            your selfie-style response in 15 minutes or less.
          </div>
          <div>
            <b>Step 5:</b>
            <div>
              You make a lasting impact on students regardless of their
              economic, societal, or geographic barriers. Congrats, your
              company's the
              <span
                style={{
                  marginLeft: "6px",
                  display: "inline-block",
                }}
              >
                <span>"</span>
                <span style={{ fontWeight: "bolder" }}>Bee's-knees</span>
                <span>"!</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  renderPartnersElement = () => (
    <div className="email-contact-us-content-become-partner-container-div">
      <div className="email-contact-us-content-become-partner-reason-title-div">
        SOME OF OUR PARTNERS INCLUDE
      </div>
      <div className="email-contact-us-content-partners-items-div">
        <div>
          <div>
            <div>
              <img src="/partner-amazon.svg" alt="" />
            </div>
            <div>
              <img src="/partner-chick-fil-a.png" alt="" />
            </div>
          </div>
          <div>
            <div>
              <img src="/partner-semi-final.png" alt="" />
            </div>
            <div>
              <img src="/partner-panasonic.png" alt="" />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>
              <img src="/partner-smart-n-final.png" alt="" />
            </div>
            <div>
              <img
                src="/partner-uber.svg"
                alt=""
                style={{ minWidth: "80%", maxWidth: "50%" }}
              />
            </div>
          </div>
          <div>
            <div>
              <img src="/partner-delta-dental.png" alt="" />
            </div>
            <div>
              <img src="/partner-health-university-of-utah.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="mail-contact-us-content-partners-comment-div">
        <div>
          "All I can say is... WOW!!! You and your team REALLY know what are you
          doing. What a great job!"
        </div>
        <div>
          <b>Alicia, Senior Director of People, Culture and DEI onsemi</b>
        </div>
      </div>
    </div>
  )

  renderForm = () => {
    const element = (
      <div>
        <div
          className="pipedriveWebForms"
          data-pd-webforms="https://webforms.pipedrive.com/f/1uJzJpYWTcM6yznQvtJS3HKv1CppGjuMd1r52qpGa2Cs1gjKci8k13nsUluOWpizp"
        ></div>
      </div>
    )
    return element
  }

  renderFormElement = () => (
    <div className="email-contact-us-content-form-container-div">
      {/* <img src="/email-contact-us-content-bee-fly.png" alt="" /> */}
      <div
        className="email-contact-us-content-become-partner-reason-title-div"
        style={{ color: "#e86726" }}
      >
        <div>SCALE YOUR MARKETING REACH EFFICIENTLY AND EFFECTIVELY</div>
        <div>WHILE INSPIRING YOUR FUTURE WORKFORCE</div>
      </div>
      <div className="email-contact-us-content-form-content-div">
        <div>START HERE</div>
        <div className="email-contact-us-content-form-background-div" />
        <div>{this.renderForm()}</div>
      </div>
    </div>
  )

  render() {
    const html = (
      <>
        <div className="email-contact-us-content-wrapper-div">
          <div className="email-contact-us-content-top-row-div" />
          {this.renderTopTextElement()}
          {this.renderVideoPresentElement()}
          {this.renderBecomePartnerReasonElement()}
          {this.renderHowItWorksElement()}
          {this.renderPartnersElement()}
          {this.renderFormElement()}
        </div>
      </>
    )
    return html
  }
}
