import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { GetBeInspirational } from '../components/emailbeinspirational-content-new';
import { TiggbeeNoBackgroundContactUs } from '../components/tiggbee-no-background-contact-us';

const IndexPage = () => (
  <Layout>
    <SEO title="Contact Us - Business" />
    <GetBeInspirational />
    <TiggbeeNoBackgroundContactUs
      titleElement={<>Together We Inspire Millions of Students</>}
      isSmallContainer={true}
      isHideButton={true}
    />
  </Layout>
)

export default IndexPage
